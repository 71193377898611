import { createI18n } from 'vue-i18n';


const optionsLangs = [
  { text: "简体中文", value: "zh-CN" },
  { text: "繁体中文", value: "zh-HK" },
]; //设置项目语言
const allowedLangs = optionsLangs.map(option => option.value);
const defaultLang = getDefLang();

function getDefLang() {
  let lang = localStorage.getItem('Language')
  if (lang && allowedLangs.indexOf(lang) !== -1) {
    return lang
  } else {
    return allowedLangs[0]
  }
}

const messages = {};
async function loadMessages() {
  for (let i = 0; i < allowedLangs.length; i++) {
    let lang = allowedLangs[i];
    if (!lang) {
      console.error('Invalid language code:', lang);
      continue;  // 跳过无效语言代码
    }
    try {
      // 使用动态 import 来加载语言 JSON 文件
      const module = await import(`@/i18n/${lang}.json`);
      messages[lang] = module.default;
    } catch (error) {
      console.error(`Error loading language file for ${lang}:`, error);
    }
  }
}
// 调用 loadMessages 函数来加载语言文件
loadMessages();

const i18n = createI18n({
  legacy: false,  // 启用 Composition API 模式
  locale: defaultLang,   // 默认语言
  messages,       // 语言资源
})

function changeLang(lang) {
  //Change language globally
  i18n.global.locale.value = ''
  i18n.global.locale.value = lang;
  localStorage.setItem('Language', lang);
}

export { i18n, changeLang, optionsLangs, getDefLang };