import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import { useStore } from 'vuex'
import HomeView from '../views/HomeView.vue'
import TabsPage from '../views/TabsPage.vue'
import TabsPcPage from '../views/TabsPcPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/newsview',
    component: HomeView
  },
  {
    path: '/tabs/',
    name: 'TabsPage',
    meta: { keepAlive: true },
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/newsview'
      },
      {
        // 资讯首页
        path: 'newsview',
        name: 'NewsPage',
        meta: { keepAlive: true },
        component: () => import(/* webpackChunkName: "newsView" */ '@/views/h5/newsView.vue')
      },
      {
        // 采购清单
        path: 'listsview',
        name: 'ListsView',
        component: () => import(/* webpackChunkName: "listsView" */ '@/views/h5/listsView.vue')
      },
      {
        // 我的
        path: 'myview',
        component: () => import(/* webpackChunkName: "myView" */  '@/views/h5/myView.vue')
      }
    ]
  },
  {
    // 新闻详情
    path: '/newsDetails',
    name: 'newsDetails',
    meta: { transition: 'fang' },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "newsDetails" */ '@/views/h5/newsDetails.vue')
  },
  {
    // 购物车清单
    path: '/shoppingcart',
    name: 'ShoppingCart',
    meta: { keepAlive: true, transition: 'fang' },
    component: () => import(/* webpackChunkName: "ShoppingCart" */ '../views/h5/shoppingCart.vue')
  },
  {
    // 我的采购清单列表
    path: '/shoppingcartlist',
    name: 'ShoppingCartList',
    meta: { keepAlive: true, transition: 'fang' },
    component: () => import(/* webpackChunkName: "ShoppingCartList" */ '../views/h5/shoppingCartList.vue')
  },
  {
    // 订单详情
    path: '/orderinfo',
    name: 'OrderInfo',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "OrderInfo" */ '../views/h5/orderInfo.vue')
  },
  {
    // 我的发件信息
    path: '/sender',
    name: 'Sender',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "Sender" */ '../views/h5/sender.vue')
  },
  {
    // 新增发件人
    path: '/addsender',
    name: 'AddSender',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "AddSender" */ '../views/h5/addSender.vue')
  },
  {
    // 地址簿
    path: '/addresslist',
    name: 'AddressList',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "AddressList" */ '../views/h5/addressList.vue')
  },
  {
    // 新增地址
    path: '/address',
    name: 'AddressPage',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "AddressPage" */ '../views/h5/address.vue')
  },
  {
    // 修改密码
    path: '/changePsw',
    name: 'ChangePsw',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "ChangePsw" */ '../views/h5/changePsw.vue')
  },
  {
    // 代客下单
    path: '/valetOrder',
    name: 'ValetOrder',
    meta: { transition: 'fang' },
    component: () => import(/* webpackChunkName: "ValetOrder" */ '../views/h5/valetOrder.vue')
  },

  // PC 端页面 -------------------------------------------------------------------------
  {
    path: '/tabsPc/',
    name: 'TabsPcPage',
    component: TabsPcPage,
    children: [
      {
        path: '',
        redirect: '/tabsPc/newsviewPc'
      },
      {
        // 资讯首页
        path: 'newsviewPc',
        name: 'NewsPagePc',
        meta: { keepAlive: true },
        component: () => import(/* webpackChunkName: "newsViewPc" */ '@/views/pc/newsViewPc.vue')
      },
      {
        // 采购清单
        path: 'listsviewPc',
        name: 'ListsViewPc',
        meta: { keepAlive: true },
        component: () => import(/* webpackChunkName: "listsViewPc" */ '@/views/pc/listsViewPc.vue')
      },
      {
        // 采购清单
        path: 'orderViewPc',
        name: 'OrderViewPc',
        meta: { keepAlive: true },
        component: () => import(/* webpackChunkName: "OrderViewPc" */ '@/views/pc/orderViewPc.vue')
      },
      {
        // 我的
        path: 'myviewPc',
        component: () => import(/* webpackChunkName: "myViewPc" */  '@/views/pc/myViewPc.vue')
      },
      {
        // 地址库
        path: 'addresslistPc',
        name: "AddresslistPc",
        component: () => import(/* webpackChunkName: "addressListPc" */  '@/views/h5/addressList.vue')
      },
      {
        // 发件人信息
        path: 'senderPc',
        name: "SenderPc",
        component: () => import(/* webpackChunkName: "senderPc" */  '@/views/h5/sender.vue')
      },
      {
        // 发件人信息
        path: 'changePsw',
        name: "ChangePswPc",
        component: () => import(/* webpackChunkName: "ChangePswPc" */  '@/views/h5/changePsw.vue')
      }
    ]
  },
  // 登录页面
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})


// 全局守卫
// router.beforeEach((to, from, next) => {
//   // 判断路由是否需要守卫
//   //meta数据的方式
//   console.log(to, from)
//   if (to) {
//     setTimeout(() => {
//       next()
//     }, 500);
//   } else {
//     //不需要守卫,直接放行
//     next()
//   }
// })

export default router
