import { Dialog } from "@nutui/nutui"
// 封装本地存储操作模块

const token = 'token'
const agentId = 'agentId'
const chinaArea = 'chinaArea'
const userInfo = 'userInfo'
const valetInfo = 'valetInfo'

/** 存储token ------------------------------- */
export const setToken = (val: string) => {
  localStorage.setItem(token, val)
}
/** 获取token */
export const getToken = () => {
  return localStorage.getItem(token)
}
/** 删除token */
export const removeToken = () => {
  localStorage.removeItem(token)
}

/** 存储代课下单id ------------------------------- */
export const setAgentId = (val: string) => {
  localStorage.setItem(agentId, val)
}
/** 获取代课下单id */
export const getAgentId = () => {
  return localStorage.getItem(agentId)
}
/** 删除代客下单id */
export const removeAgentId = () => {
  localStorage.removeItem(agentId)
}


/** 存储省市区 ----------------------------- */
export const setArea = (val: object) => {
  localStorage.setItem(chinaArea, JSON.stringify(val))
}
/** 获取省市区 */
export const getArea = () => {
  return localStorage.getItem(chinaArea) ? JSON.parse(localStorage.getItem(chinaArea) as string) : false
}
/** 删除省市区 */
export const removeArea = () => {
  localStorage.removeItem(chinaArea)
}


/** 存储用户信息 ----------------------------- */
export const setUserInfo = (val: object) => {
  localStorage.setItem(userInfo, JSON.stringify(val))
}
/** 获取用户信息 */
export const getUserInfo = () => {
  return localStorage.getItem(userInfo) ? JSON.parse(localStorage.getItem(userInfo) as string) : false
}
/** 删除用户信息 */
export const removeUserInfo = () => {
  localStorage.removeItem(userInfo)
}

/** 存储代客下单信息 ----------------------------- */
export const setValetInfo = (val: object) => {
  localStorage.setItem(valetInfo, JSON.stringify(val))
}
/** 获取代客下单信息 */
export const getValetInfo = () => {
  return localStorage.getItem(valetInfo) ? JSON.parse(localStorage.getItem(valetInfo) as string) : false
}
/** 删除代客下单信息 */
export const removeValetInfo = () => {
  localStorage.removeItem(valetInfo)
}


/** 退出询问 -------------------------------- */
export const signOut = (Router: any) => {
  Dialog({
    title: "提示",
    content: "确定退出吗",
    onCancel: () => { },
    onOk: () => {
      Router.go(0);
      clear()
    },
  })
}

/** 退出清除数据 */
export const clear = () => {
  removeToken()
  removeArea()
  removeUserInfo()
  removeValetInfo()
  removeAgentId()
}

/** 清除代课下单数据 */
export const clearValet = () => {
  removeValetInfo()
  removeAgentId()
}

/** 是否登录中 */
export const isLogin = () => {
  return getToken() ? true : false
}