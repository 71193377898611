import axios from "axios";
import API from "./api";
import { Toast } from "@nutui/nutui"
import { getToken, getAgentId } from "@/utils/storage"
import { emitter } from "@/mitt/mitt"
import { clear } from "@/utils/storage"
import router from "@/router/index"

const baseUrl: any = {
  'a': process.env.VUE_APP_BASE_URL_A,//本地
  'c': process.env.VUE_APP_BASE_URL_B//服务器2
}
const baseKey: any = 'a'

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";

// 表示跨域请求时是否需要使用凭证 允许跨域携带cookie信息
axios.defaults.withCredentials = false;

// 允许跨域
axios.defaults.headers.post["Access-Control-Allow-Origin-Type"] = "*";

//设置超时
axios.defaults.timeout = 15 * 1000;

axios.interceptors.request.use(
  (config: any) => {
    Toast.loading('', {
      cover: true
    });
    if (getToken()) {
      config.headers['Authorization'] = `Bearer ` + getToken(); // 让每个请求携带自定义token
    }
    if (getAgentId()) {
      config.headers['Other-Agent-ID'] = getAgentId()
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  response => {
    Toast.hide()
    if (response.status == 200 && response.config.url === '/static/china_app.json') {
      // 获取省市区 特殊处理
      return Promise.resolve(response);
    }
    if (response.status == 200 && response.data.err_code === 0) {
      return Promise.resolve(response);
    } else {
      let text = response.data.data.err_desc || response.data.err_msg || '请求异常'
      Toast.text(text)
      return Promise.reject(response);
    }
  },
  error => {
    Toast.hide()
    // 401 未登录
    if (error.response.status === 401) {
      clear()
      // emitter.emit("showLoginPage", true)
      // console.log(router.options.history.state.back, '99999')
      let text = '请先登录'
      Toast.text(text)
      if (router.options.history.state.back) {
        router.replace({ path: "/login" })
      } else {
        router.push({ path: "/login" })
      }
    } else {
      let text = error.response.data.data.err_desc || error.response.data.err_msg || "请求异常"
      Toast.text(text)
    }
  }
);


export default {
  /**
   * @param {String} url 
   * @param {Object} data 
   * @returns Promise
   */
  post(url: string, data?: object, url2?: string, baseURL = baseKey) {
    axios.defaults.baseURL = baseUrl[baseURL]
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: url2 || API[url],
        data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        });
    })
  },

  get(url: string, data?: object, url2?: string, baseURL = baseKey) {
    axios.defaults.baseURL = baseUrl[baseURL]
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: url2 || API[url],
        params: data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  put(url: string, data?: object, url2?: string, baseURL = baseKey) {
    axios.defaults.baseURL = baseUrl[baseURL]
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: url2 || API[url],
        data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  delete(url: string, data?: object, url2?: string, baseURL = baseKey) {
    axios.defaults.baseURL = baseUrl[baseURL]
    return new Promise((resolve, reject) => {
      axios({
        method: 'delete',
        url: url2 || API[url],
        data,
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
};