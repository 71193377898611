import BigNumber from "bignumber.js";
//计算精度问题
function arithmetic(params, type) {
  let x = new BigNumber(params[0]);
  for (var i = 1; i < params.length; i++) {
    if (type === "+") {
      x = x.plus(params[i]);
    } else if (type === "-") {
      x = x.minus(params[i]);
    } else if (type === "*") {
      x = x.multipliedBy(params[i]);
    } else if (type === "/") {
      x = x.dividedBy(params[i]);
    }
  }
  return x.toNumber();
}
/**
 * plus
 * params eg:plus(1,2,3,4) express 1*2*3*4
 */
function nb1(...params) {
  return arithmetic(params, "+");
}

function nb2(...params) {
  return arithmetic(params, "-");
}

function nb3(...params) {
  return arithmetic(params, "*");
}

function nb4(...params) {
  return arithmetic(params, "/");
}

/* 货币符号 HK$/￥ */
const symbol = "HK$"


let global = {
  install: (app) => {
    // 注入一个全局可用的 $translate() 方法
    app.config.globalProperties.$nb1 = nb1;
    app.config.globalProperties.$nb2 = nb2;
    app.config.globalProperties.$nb3 = nb3;
    app.config.globalProperties.$nb4 = nb4;
    app.config.globalProperties.$symbol = symbol;
  },
};

export {
  nb1,
  nb2,
  nb3,
  nb4,
  symbol
};

export default global;