import { createStore } from 'vuex';
import { useI18n } from 'vue-i18n';

export default createStore({
  state: {
    name: '张三',
    env: 'h5',//当前运行环境pc、h5
    clientWidth: 900,
    cartUpdate: 0,// 加入购物车成功刷新购物车数据
    // dutyTips: '本网站只负责报价'
  },
  getters: {
    dutyTips: (state) => {
      const { t } = useI18n(); // 使用 useI18n 获取 t 函数
      return t('newsView.t4'); // 获取翻译文本
    },
    GET_clientWidth(state) {
      return state.clientWidth
    },
    GET_ENV(state) {
      return state.env
    },
  },
  mutations: {
    SET_CLIENTWIDTH(state, data) {
      state.clientWidth = data
    },
    SET_ENV(state, data) {
      state.env = data
    },
    SET_CARTUPDATE(state) {
      state.cartUpdate++
    }
  },
  actions: {
  },
  modules: {
  }
})
